.addButton {
  bottom: 5rem;
  right: 2rem;

  ion-fab-button[data-label] {
    position: relative;
  }

  ion-fab-button[data-label]::after {
    position: absolute;
    content: attr(data-label);
    z-index: 1;
    right: 3rem;
    bottom: 0.75rem;
    color: var(--ion-color-dark);
    background-color: var(--ion-color-light);
    border-radius: 0.3125rem;
    padding: 0.625rem;
    margin-bottom: -0.625rem;
    box-shadow:
      0 0.1875rem 0.3125rem -0.0625rem rgb(0 0 0 / 20%),
      0 0.375rem 0.625rem 0 rgb(0 0 0 / 14%),
      0 0.0625rem 0.625rem 0 rgb(0 0 0 / 12%);
  }
}
