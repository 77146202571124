.thumbnailPhotoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-dark);
  border-width: 0.025rem;
  border-style: solid;
  position: relative;
  height: 8rem;
  width: 8rem;
  margin: 1rem;
  cursor: pointer;
  border-color: var(--ion-color-dark);

  &.success {
    ion-icon {
      color: var(--ion-color-dark);
    }
  }

  &.error {
    background-color: var(--ion-color-danger);
  }
}

.thumbnailPhoto {
  max-height: 8rem;
  max-width: 8rem;
}

.retryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: auto;
}

.topRightIcon {
  position: absolute;
  color: var(--ion-color-dark);
  top: 0;
  right: 0;
}

.spinner {
  font-weight: bold;
  justify-content: center;
  color: var(--ion-color-dark);
  width: 2rem;
  height: 2rem;
  margin-right: 0.125rem;
}

.testDiv {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 50rem;
  width: 100%;
}
