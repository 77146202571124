.pageContainer {
  justify-content: flex-start;
}

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  content: " ";
  width: 100%;
  height: 16rem;
  background-color: var(--theme-mid-dark-blue);
}

.promoImage {
  position: absolute;
  width: 20rem;
  z-index: 10;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 60%;
  width: 100%;
}

.caption {
  font-size: "2rem";
  font-weight: 700;
  z-index: 99;
}

.button {
  padding: 0 1.8rem;
  border-radius: var(--theme-rounding);
  background-color: var(--theme-mid-dark-blue);
}

.routerLinkText {
  color: var(--ion-item-background);
  font-weight: 700;
  margin: 0.5rem;
}

.notification {
  text-align: center;
  margin: 0 1rem;
  z-index: 99;
}

.inviteText {
  margin: 0;
}
