.subTitle {
  padding: 0.5rem;
}

.phoneInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 0 0;
  background-color: inherit;
  border: 0;
  padding: 1rem 0;
  outline: none;
  border: none;
}

.phoneInput input {
  border: 0;
  outline: none;
  background-color: white;
}

.errorMessage {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
}

.contentContainer {
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.4rem 0 0.8rem;
}

.label {
  font-size: 1rem;
}

.subtext {
  font-size: 0.7rem;
}

.errorMessage {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
}
