.listContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.itemContent {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 0.4rem;
  background-color: var(--ion-item-background);
  padding: 0.5rem 1rem;
  border: 0.05rem solid var(--ion-color-medium);
  margin-bottom: 0.5rem;
}

.done {
  background-color: var(--theme-status-completed);
  padding: 0.8em;
  border-color: var(--theme-status-completed-dark);
  border-style: solid;
  border-width: 0.15em;

  ion-badge {
    background-color: var(--theme-status-completed-dark);
  }
}

.next {
  background-color: var(--theme-status-waiting-on-user);
  padding: 0.8em;
  border-color: var(--theme-status-waiting-on-user-dark);
  border-style: solid;
  border-width: 0.15em;

  ion-badge {
    background-color: var(--theme-status-waiting-on-user-dark);
  }
}

.taskIcon {
  font-size: 1.8rem;
  color: var(--ion-color-medium);
  margin-inline-end: 0.5rem;
}

.label {
  flex-grow: 1;
}
