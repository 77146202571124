.setLeadPreferencesPage {
  .backIcon {
    font-size: 1.3em;
  }

  ion-content {
    h3 {
      margin: 0;
    }

    ion-button {
      --background: var(--ion-color-dark-contrast);
      --padding-top: 1.5em;
      --padding-bottom: 1.5em;
      --box-shadow: var(--box-shadow);

      text-transform: none;
      margin-bottom: 2em;
      font-size: 1.2em;

      ion-icon {
        margin-right: 0.8em;
        font-size: 1.2em;
      }

      span {
        margin-right: auto;
      }
    }
  }

  .subtitle {
    color: var(--ion-color-light-grey);
    font-size: 1.2em;
  }

  @media only screen and (min-width: 768px) {
    ion-header {
      max-width: 50%;
      margin: auto;
    }
  }
}
