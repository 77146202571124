.modalContainer {
  padding: 0 1rem 2rem;
}

.addItem {
  ion-avatar {
    background-color: var(--ion-color-light-shade);
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon {
      color: var(--ion-color-light-contrast);
      font-size: 1.2rem;
    }
  }
}
