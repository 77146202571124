.textContainer {
  height: 17rem;
}

.title {
  font-size: 1.4em;
  font-weight: 900;
}

.centeredText {
  p {
    font-size: 0.9rem;
    margin: 0;
  }
}

.projectPhoto {
  max-height: 19rem;
  width: 100%;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
