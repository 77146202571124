.ionContent {
  height: 100vh;
  overflow: auto;
}

.title {
  font-size: 1.4em;
  font-weight: 900;
}

.centeredText {
  text-align: center;

  p {
    font-size: 0.9rem;
  }
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;

  ion-icon {
    font-size: 1.1rem;
  }
}

.centeredVertically {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 32rem;
  line-height: 1.5rem;
}

.ionItem {
  --padding-end: 0;
  --ion-item-background: none;

  line-height: 1.5rem;

  ion-icon {
    font-size: 2rem;
  }

  overflow: hidden;
}

.ionFooter {
  height: 6rem;
}

.ionImg {
  max-height: 22rem;
  width: 100%;
}

.ionButton {
  height: 2.5em;
  width: 10em;
}
