.uploadBubble {
  display: flex;
  flex-direction: column;
  color: var(--ion-color-dark);
  font-weight: normal;
  font-size: 0.75rem;
  align-self: flex-start;
  min-height: 1.9rem;
  text-transform: lowercase;
  border-radius: 1.5625rem;
  border-width: 0.025rem;
  border-style: solid;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  flex: 100%;
  align-items: center;
  justify-content: center;
  border-color: var(--ion-color-dark);
  background-color: var(--ion-background-color);

  ion-icon {
    margin-left: 0.25rem;
    margin-right: 0.125rem;
  }

  &.success {
    ion-icon {
      color: var(--ion-color-success-shade);
    }
  }

  &.error {
    background-color: var(--ion-color-danger);

    ion-icon {
      color: var(--ion-color-dark);
    }
  }
}

.retryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: auto;
}

.uploadingFileStatusSpinner {
  font-weight: bold;
  color: var(--ion-color-dark);
  height: 0.75rem;
  margin-right: 0.125rem;
}
