.buttonIcon {
  margin-right: 0.5rem;
}

.ionItem {
  --ion-item-background: var(--ion-color-medium-contrast);
}

.cursorEnabled {
  cursor: pointer;
}

.ionContent {
  --ion-background-color: var(--weaver-profile-background-color);

  p {
    margin: 0;
  }

  ion-list {
    margin-top: 0;
    padding-top: 0;
  }

  section {
    padding: 0 1em 0.5em;
  }

  .companyInfoContainer {
    background-color: var(--ion-color-medium-contrast);
    box-shadow: var(--theme-custom-box-shadow);
    padding-bottom: 0.5em;

    p {
      color: var(--ion-color-light-grey);
    }

    svg {
      margin-top: 0.5em;
      margin-right: 1em;
    }

    ion-icon {
      color: var(--theme-light-blue);
      height: 0.8em;
      margin-left: 0.5em;
    }

    .companyTradingName {
      margin-bottom: 0;
      max-width: 70%;
    }

    .contractorTeamType {
      margin: 0.5em 0;
    }
  }

  .unlockedBudgetRangesContainer {
    display: flex;
    flex-direction: column;

    h3 {
      margin-top: 0;
    }

    ion-button {
      margin-top: 1em;
      max-width: 18em;
    }
  }
}
