.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.25rem;
  margin: 0;
  height: 100%;
  width: 100%;
}

.swiper {
  height: 100%;
  max-width: 95vw;
}

.slideContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
}

.contentContainer {
  width: 100%;
  height: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.8rem;
}

.label {
  font-size: 1rem;
}

.profileImageContainer {
  display: flex;
  align-items: center;
  max-width: 15rem;
}

.profileImage {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.5rem;
  color: var(--ion-color-medium);
}

.subtext {
  font-size: 0.7rem;
}

.errorMessage {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
}

.addressAutoComplete {
  // Width used to ensure googleAutocomplete dropdown
  // lines up with container
  width: 98%;
  background-color: inherit;
  border: 0;
  padding: 1rem 0;
  outline: none;
}

.displayRows {
  padding: 0.5rem 0;
}

.outerGrid {
  margin: 0;
}
