.ionToolbar {
  --background: var(--weaver-profile-background-color);
}

.ionIcon {
  font-size: 1.3em;
}

@media only screen and (min-width: 768px) {
  .tradingNameHeader {
    max-width: 50%;
    margin: auto;
  }
}
