.ionToolbar {
  --background: transparent;
}

.photosContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 10em);
  grid-template-rows: auto;

  .photoItemContainer {
    img {
      object-fit: contain;
      height: 100%;
    }
  }
}
