.lightGrey {
  color: var(--ion-color-light-grey);
}

.ionItem {
  --ion-item-background: none;
}

.ionItemInput {
  --border-width: 0.1em;
  --border-radius: 0.3em;
}

.confirmButton {
  margin-top: 1em;
  height: 2.5em;
  width: 8em;
  font-size: 0.8em;
}

.ionContent {
  --ion-background-color: var(--weaver-profile-background-color);
}
