.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.8rem 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
}

.childItem {
  width: 100%;
  border-radius: 0.4rem;
  border: 0.05rem solid var(--ion-color-medium);
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.selected {
  border: 0.15rem solid var(--ion-color-primary);
  font-weight: 500;
}

.companySelector {
  flex: auto;
  flex-grow: 1;
}

.contentContainer {
  height: 100%;
}

.virtuosoContainer {
  height: 100%;
}
