.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  background: var(--ion-color-light-contrast);
  width: 100%;

  & > iframe {
    height: 100%;
    width: 100%;
    border: 1px solid var(--ion-color-light);
  }
}

.helpAccordion h1 {
  h1 {
    margin-left: 1rem;
  }

  ion-item[slot="header"] > ion-label {
    font-size: 1.125em;
    white-space: normal;
  }

  main {
    margin: 1rem 1rem 1rem 0;
  }
}
