.subItemDrawer {
  padding: 0 0 0 2rem;

  &.subItemContracted {
    height: 0;
    opacity: 0;
    transition: 0.2s ease-out;
  }

  &.subItemExpanded {
    height: 4rem;
    opacity: 1;
    margin-bottom: 0.4rem;
    transition: 0.2s ease-out;
  }
}

.subtextContainer {
  display: flex;
  color: var(--ion-color-medium);
  margin-top: 0.2rem;
  flex-wrap: wrap;
  gap: 0;

  div {
    display: flex;
    gap: 0.1rem;
    margin-right: 0.2rem;

    span {
      font-size: 0.8rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

// top level task item
.taskItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ion-item-background);
  width: 100%;
  height: 4rem;
  border-radius: 0.4rem;
  border: 1px solid var(--ion-color-medium);
  margin-bottom: 0.4rem;

  &:hover {
    border: 1px solid var(--ion-color-primary);
  }

  &.unactionable {
    opacity: 0.5;
  }

  .itemContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    // When using 100% to make the flexed element as big as possible in it's flexed parent,
    // a min-width is required to prevent it from pushing other elements into overflow
    min-width: 1px;

    ion-label {
      display: flex;
      align-items: center;
    }
  }

  .statusContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--ion-color-medium);
    gap: 0.4rem;
    white-space: nowrap;
    flex: column nowrap;

    div {
      display: flex;
      gap: 0.2rem;
      justify-content: flex-end;
    }

    ion-icon {
      font-size: 1.3rem;
    }
  }
}

.taskIcon {
  font-size: 1.8rem;
  color: var(--ion-color-medium);
  margin-inline-end: 0.5rem;
}

.modal {
  .modalBody {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
}
