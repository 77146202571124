.ionList {
  margin-top: 4em;

  .inviteTeamMemberText {
    color: var(--ion-color-light-grey);

    .inviteTeamMemberSubtitle {
      margin-bottom: 0.5em;
    }
  }
}

.individualConnectionItem {
  margin-top: 0.5em;

  --inner-padding-start: 0.5em;
  --inner-padding-top: 0.5em;
  --background: var(--ion-item-background);
  --border-radius: 0.3em;
  --inner-padding-bottom: 0.7em;

  ion-label {
    margin-left: 0.5em;
  }
}
