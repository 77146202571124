.content {
  * {
    font-weight: bold;
  }

  h3 {
    margin-bottom: 1.5em;
  }

  ion-item > div {
    text-align: center;
    width: 100%;

    :not(:first-child) {
      margin-top: -0.5em;
      margin-bottom: 0.5em;
      font-weight: lighter;
    }

    ion-icon {
      margin-left: 0.5em;
    }
  }

  ion-item {
    border: none;
  }

  .notStarted {
    --ion-item-background: var(--theme-status-not-started);
  }

  .waitingOnWeaver {
    --ion-item-background: var(--theme-status-waiting-on-weaver);
  }

  .completed {
    --ion-item-background: var(--theme-status-completed);
  }

  .waitingOnUser {
    --ion-item-background: var(--theme-status-waiting-on-user);
  }
}

.ionToolbar {
  --background: transparent;
}
