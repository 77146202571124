.lightGrey {
  color: var(--ion-color-light-grey);
}

.buttonIcon {
  margin-right: 0.5rem;
}

.editIcon {
  margin-left: 0.2em;

  // solves the issue of tiny edit icon when companyTradingAs is too long
  font-size: min(2.2em);
}

.ionItem {
  --ion-item-background: none;
}

.cursorEnabled {
  cursor: pointer;
}

.ionContent {
  --ion-background-color: var(--weaver-profile-background-color);
}
