.container {
  max-width: 40rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.weaverLogo {
  width: 15em;
  margin: auto;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--theme-weaver-brand);
  z-index: -1;
}

.caption {
  text-align: center;
  color: var(--ion-color-medium-contrast);
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.loginButton {
  margin-top: 2rem;
  width: 70%;
  height: 4rem;
  font-size: 1.5rem;
}

.promoImage {
  max-width: 28rem;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 45rem) {
  .caption {
    font-size: 1.7rem;
  }

  .loginButton {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    height: 2.5rem;
  }

  .promoImage {
    max-width: 20rem;
  }
}
