.next {
  --ion-item-background: var(--theme-item-next);

  border-color: var(--theme-item-next-dark);
  border-style: solid;
  border-width: 0.185em;

  ion-badge {
    background-color: var(--theme-item-next-dark);
  }
}

.waiting {
  --ion-item-background: var(--theme-status-waiting-on-weaver);

  border-color: var(--theme-status-waiting-on-weaver-dark);
  border-style: solid;
  border-width: 0.185em;

  ion-badge {
    background-color: var(--theme-status-waiting-on-weaver);
  }
}

.toDo {
  ion-badge {
    background-color: var(--ion-color-light-grey);
  }
}

.done {
  --ion-item-background: var(--theme-status-completed);

  border-color: var(--theme-status-completed-dark);
  border-style: solid;
  border-width: 0.185em;

  ion-badge {
    background-color: var(--theme-status-completed-dark);
  }
}
