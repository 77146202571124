.imageContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--theme-weaver-brand);
}

@keyframes image-breath {
  0% { height: 15rem; }
  0% { opacity: 0.7; }
  25% { opacity: 0.8; }
  50% { height: 20rem; }
  50% { opacity: 1; }
  75% { opacity: 0.8; }
  100% { height: 15rem; }
  100% { opacity: 0.7; }
}

.img {
  animation: image-breath 2.5s ease-in-out infinite;
  margin-bottom: 5vh;
}
