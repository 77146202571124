.contractorWorkHistoryContainer {
  ion-icon {
    margin-right: 1em;
    min-width: 1em;
  }

  p {
    margin-top: 0.5em;
  }

  p:nth-child(2) {
    margin-bottom: 1em;
  }

  ion-item {
    --inner-padding-bottom: 0.5em;

    border-radius: var(--theme-rounding);
    margin-bottom: 1em;
  }
}

.workHistoryTextContainer {
  .costOfWorkText {
    color: var(--ion-color-light-grey);
    font-size: smaller;
  }

  .projectAddress {
    margin-top: 0.5em;
  }

  ion-badge {
    --padding-end: 1.2em;
    --padding-start: 1.2em;

    margin-right: 0.5em;
    border-radius: 1em;
  }

  .noWorkHistoryText {
    font-size: medium;
  }
}
