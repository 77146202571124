.uploadPhotosButtonContainer {
  flex-direction: row;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.uploadPhotosButton {
  color: var(--ion-color-dark);
  width: 20rem;
}

.uploadPhotosButtonIcon {
  margin-right: 1rem;
}
